.box {
    border-radius: 10px 10px 10px 10px;
    overflow: auto;
    height: 200px;
    max-height: 100vh;
    -webkit-box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);
    box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);
    margin: 10px;    

}

.box2 {
    border-radius: 10px 10px 10px 10px;
    overflow: auto;
    height: 400px;
    max-height: 100vh;
    -webkit-box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);
    box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);
    margin: 10px;    
}

.grid {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    flex-grow: 3;
}

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
  }

  
.card-image {
    height: 100px;
    width: 5px;
    border-radius: 25px 25px 100px 100px;
}
.chart{
    height: 500px;
    padding: 10px;
}


