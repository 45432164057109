.projects {
    background-image: url(../images/circuit-board.svg);
    text-align: center;
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    background-position: center; 
}
.dark-background {
    background-color: rgba(0,0,0,.9);
    color: #D3D3D3;
    font-family: 'Staatliches', cursive;
    align-items: center;
    max-height: 3em;
    justify-self: center;
    margin-top: 5px;
}

.dark {
    background-color: rgba(212, 212, 212, 0.9);
    color: #000000;
    position: center; 
    margin-top: 10px;
    margin-right: 500px;
    margin-bottom: 10px;
    margin-left: 500px;
    font-family: 'Roboto', sans-serif;
}
